import AsyncStorage from '@react-native-async-storage/async-storage';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';
import useAuthUser from '../../hooks/useAuthUser';
import useMspVerified from '../../hooks/useMspVerified';
import useUserTypeCheck from '../../hooks/useUserTypeCheck';
import { useAppSelector } from '../../store';
import { serializeTicketParam, STORAGE_STORED_TICKET_PARAM } from '../../util/constants';
import CertificatesNavigator, { CertificatesNavigatorParamList } from '../Certificates/CertificatesNavigator';
import LoginNavigator, { LoginNavigatorParamList } from '../Login/LoginNavigator';
import MspRegisterNavigator from '../MspRegister/MspRegisterNavigator';
import { NestedNavigateParams, RootNavigationProp } from '../Navigation/RootNavigator';
import SettingsNavigator, { SettingsNavigatorParamList } from '../Settings/SettingsNavigator';
import TicketsNavigator from '../Tickets/TicketsNavigator';
import VTIcon from '../UI/VTIcon';
import VerifyNavigator from '../Verify/VerifyNavigator';
import NavigationDrawerContent from './NavigationDrawerContent';

export type MainNavigatorParamList = {
  CertificatesNavigator: undefined;
  VerifyNavigator: undefined;
  MspRegisterNavigator: undefined;
  TicketsNavigator: undefined;
  LoginNavigator: NestedNavigateParams<LoginNavigatorParamList>;
  SettingsNavigator: NestedNavigateParams<SettingsNavigatorParamList>;
};

const Drawer = createDrawerNavigator<MainNavigatorParamList>();

export default () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const navigation = useNavigation<RootNavigationProp>();

  const ticketParam = (navigation.dangerouslyGetState().routes[0]?.state
    ?.routes[0]?.state?.routes[1]
    ?.params as CertificatesNavigatorParamList['CertificatesGetNewScreen'])?.t;

  useEffect(() => {
    if (!authUser && ticketParam) {
      void AsyncStorage.setItem(
        STORAGE_STORED_TICKET_PARAM,
        serializeTicketParam(ticketParam),
      );

      navigation.navigate('MainNavigator', {
        screen: 'LoginNavigator',
        params: {
          screen: 'LoginScreen',
          params: {
            mode: 'signUp',
            type: 'patient',
          },
        },
      });
    }
  }, [authUser, navigation, ticketParam]);

  const mainScreen = useAppSelector(({ root: { mainScreen } }) => mainScreen);
  const notificationPath = useAppSelector(
    ({ root: { notificationPath } }) => notificationPath,
  );

  const showPatientFeatures = useUserTypeCheck('patient');
  const showMspFeatures = useUserTypeCheck('msp');
  const isMspVerified = useMspVerified();
  const linkTo = useLinkTo();

  useEffect(() => {
    if (!showPatientFeatures || !notificationPath) {
      return;
    }

    linkTo(notificationPath);
  }, [linkTo, notificationPath, showPatientFeatures]);

  return (
    <Drawer.Navigator
      initialRouteName={mainScreen ?? undefined}
      drawerContent={(props) => <NavigationDrawerContent {...props} />}
      // drawerType="back" // back will lead to a bug where opacity=0 after signing HIPAA 🤔
    >
      {showPatientFeatures && (
        <Drawer.Screen
          name="CertificatesNavigator"
          component={CertificatesNavigator}
          options={{
            title: t('certificates.title'),
            drawerIcon: (props) => <VTIcon {...props} name="certificates" />,
          }}
        />
      )}
      {showMspFeatures &&
        (isMspVerified ? (
          <Drawer.Screen
            name="TicketsNavigator"
            component={TicketsNavigator}
            options={{
              title: t('tickets.title'),
              drawerIcon: (props) => <VTIcon {...props} name="mspRegister" />,
            }}
          />
        ) : (
          <Drawer.Screen
            name="MspRegisterNavigator"
            component={MspRegisterNavigator}
            options={{
              title: t('mspRegister.title'),
              drawerIcon: (props) => <VTIcon {...props} name="mspRegister" />,
            }}
          />
        ))}
      <Drawer.Screen
        name="VerifyNavigator"
        component={VerifyNavigator}
        options={{
          title: t('verify.title'),
          drawerIcon: authUser
            ? undefined
            : (props) => <VTIcon {...props} name="verify" />,
        }}
      />
      {(Platform.OS === 'web' || authUser) && (
        <Drawer.Screen
          name="SettingsNavigator"
          component={SettingsNavigator}
          options={{
            title: t('settings.title'),
          }}
        />
      )}
      {!authUser && (
        <Drawer.Screen
          name="LoginNavigator"
          component={LoginNavigator}
          options={{ title: t('login.title') }}
        />
      )}
    </Drawer.Navigator>
  );
};
