import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator, StackNavigationProp } from '@react-navigation/stack';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useAuthUser from '../../hooks/useAuthUser';
import CertificatesService from '../../services/CertificatesService';
import { query$ } from '../../services/FirestoreService';
import RemindersService from '../../services/RemindersService';
import ReportsService from '../../services/ReportsService';
import { actions as certificateActions } from '../../store/certificates.slice';
import { deserializeTicketParam, STORAGE_STORED_TICKET_PARAM, TICKET_PARAM_NAME } from '../../util/constants';
import CloseButton from '../Main/CloseButton';
import NotificationsButton from '../Main/NotificationsButton';
import { navigationHeaderOptions } from '../Main/shared';
import CertificatesDetailScreen from './CertificatesDetailScreen';
import CertificatesGetNewScreen, { InputMode } from './CertificatesGetNewScreen';
import CertificatesScreen from './CertificatesScreen';
import CertificatesShareScreen from './CertificatesShareScreen';
import IssuesCreateScreen from './IssuesCreateScreen';
import NotificationsScreen from './NotificationsScreen';
import RemindersCreateScreen from './RemindersCreateScreen';
import RemindersDetailScreen from './RemindersDetailScreen';
import ReportsCreateScreen from './ReportsCreateScreen';
import ReportsDetailScreen from './ReportsDetailScreen';

export type CertificatesNavigatorParamList = {
  CertificatesScreen: undefined;
  CertificatesGetNewScreen?: {
    [K in typeof TICKET_PARAM_NAME]?: string;
  } & {
    mode?: InputMode;
  };
  ReportsCreateScreen: undefined;
  CertificatesShareScreen: {
    id: string;
  };
  CertificatesShareQRScreen: {
    code: string;
  };
  CertificatesDetailScreen: {
    id: string;
  };
  ReportsDetailScreen: {
    id: string;
  };
  RemindersCreateScreen: undefined;
  RemindersDetailScreen: {
    id: string;
  };
  IssuesCreateScreen: {
    id: string;
  };
  NotificationsScreen: undefined;
};

export type CertificatesNavigationProp = StackNavigationProp<CertificatesNavigatorParamList>;

const CertificatesStack = createStackNavigator<CertificatesNavigatorParamList>();

export default () => {
  const { t } = useTranslation();
  const uid = useAuthUser()?.uid;
  const dispatch = useDispatch();
  const navigation = useNavigation<CertificatesNavigationProp>();

  useEffect(() => {
    if (!uid) {
      return;
    }

    const certificatesListener = query$(
      CertificatesService.instance.collection(uid),
    )((c) => dispatch(certificateActions.setCertificates(c)));

    const remindersService = query$(
      RemindersService.instance.collection(uid),
    )((c) => dispatch(certificateActions.setReminders(c)));

    const reportsListener = query$(
      ReportsService.instance.collection(uid),
    )((c) => dispatch(certificateActions.setReports(c)));

    return () => {
      certificatesListener();
      remindersService();
      reportsListener();
    };
  }, [dispatch, uid]);

  useEffect(() => {
    async function checkParam() {
      const storedStr = await AsyncStorage.getItem(STORAGE_STORED_TICKET_PARAM);

      if (storedStr) {
        const res = deserializeTicketParam(storedStr);
        await AsyncStorage.removeItem(STORAGE_STORED_TICKET_PARAM);

        if (res && res.expiry > Date.now()) {
          navigation.navigate('CertificatesGetNewScreen', {
            t: res.code,
          });
        }
      }
    }

    void checkParam();
  }, [navigation]);

  return (
    <CertificatesStack.Navigator>
      <CertificatesStack.Screen
        name="CertificatesScreen"
        component={CertificatesScreen}
        options={{
          title: t('certificates.title'),
          headerRight: () => <NotificationsButton />,
          ...navigationHeaderOptions(true),
        }}
      />
      <CertificatesStack.Screen
        name="CertificatesGetNewScreen"
        component={CertificatesGetNewScreen}
        options={{
          title: t('certificates.create'),
          ...navigationHeaderOptions(),
        }}
      />
      <CertificatesStack.Screen
        name="ReportsCreateScreen"
        component={ReportsCreateScreen}
        options={{
          title: t('certificates.selfReport'),
          ...navigationHeaderOptions(),
        }}
      />
      <CertificatesStack.Screen
        name="RemindersCreateScreen"
        component={RemindersCreateScreen}
        options={{
          title: t('certificates.createReminder'),
          ...navigationHeaderOptions(),
        }}
      />
      <CertificatesStack.Screen
        name="IssuesCreateScreen"
        component={IssuesCreateScreen}
        options={{
          title: t('issues.create'),
          ...navigationHeaderOptions(),
          headerLeft: () => (
            <CloseButton popToTopFallback="IssuesCreateScreen" />
          ),
        }}
      />
      <CertificatesStack.Screen
        name="CertificatesShareScreen"
        component={CertificatesShareScreen}
        options={{
          title: t('certificates.shareTitle'),
          ...navigationHeaderOptions(),
        }}
      />
      <CertificatesStack.Screen
        name="CertificatesDetailScreen"
        component={CertificatesDetailScreen}
        options={{
          title: t('certificates.details'),
          ...navigationHeaderOptions(),
        }}
      />
      <CertificatesStack.Screen
        name="ReportsDetailScreen"
        component={ReportsDetailScreen}
        options={{
          title: t('certificates.report'),
          ...navigationHeaderOptions(),
        }}
      />
      <CertificatesStack.Screen
        name="RemindersDetailScreen"
        component={RemindersDetailScreen}
        options={{
          title: t('certificates.reminder'),
          ...navigationHeaderOptions(),
        }}
      />
      <CertificatesStack.Screen
        name="NotificationsScreen"
        component={NotificationsScreen}
        options={{
          title: t('certificates.notifications'),
          ...navigationHeaderOptions(),
        }}
      />
    </CertificatesStack.Navigator>
  );
};
