import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../../../util/theme';
import useCameraAvailable from '../../UI/BarcodeScanView/hooks/useCameraAvailable.native';
import BreakoutSection from '../../UI/BreakoutSection';
import VTButton from '../../UI/VTButton';
import VTText from '../../UI/VTText';
import { CertificatesNavigationProp } from '../CertificatesNavigator';

export default () => {
  const { t } = useTranslation();
  const navigation = useNavigation<CertificatesNavigationProp>();
  const cameraAvailable = useCameraAvailable();

  return (
    <>
      <VTText paragraph>{t('certificates.scanInfo')}</VTText>
      {cameraAvailable && (
        <VTButton
          variant="contained"
          startIcon="qrCode"
          title={t('certificates.scanConfirmationCode')}
          onPress={() => {
            navigation.navigate('CertificatesGetNewScreen', {
              mode: 'scan',
            });
          }}
        />
      )}
      <VTButton
        variant={cameraAvailable ? undefined : 'contained'}
        title={
          cameraAvailable
            ? t('certificates.enterCodeManually')
            : t('certificates.enterCode')
        }
        onPress={() => {
          navigation.navigate('CertificatesGetNewScreen', {
            mode: 'manual',
          });
        }}
      />
      {cameraAvailable && (
        <BreakoutSection style={{ marginTop: theme.spacing(2) }}>
          <VTText paragraph>{t('certificates.uploadProofDescription')}</VTText>
          <VTButton
            variant="contained"
            startIcon="sendFile"
            title={t('certificates.uploadProof')}
            onPress={() => {
              navigation.navigate('CertificatesGetNewScreen', {
                mode: 'proof',
              });
            }}
          />
        </BreakoutSection>
      )}
    </>
  );
};
